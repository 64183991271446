import {calculationRelevant} from "@/store/decorators";
import {PartObj} from "@/store/PartObj";

export class WireformObj extends PartObj {
    @calculationRelevant
    PartType = "W"       // wireform
    PartMaterial = 187         // incorporate mild steel from the boolean, MS by default
    WireDiameter = null
    Cuts = null
    Ops = null              // e.g. deburring
    Complex = false  // we're not doing a 3D drawing then
    WfStructure = []     // a bunch of BendObjs
    @calculationRelevant
    Units = 'M'     // metric by default, always
    Results = {};
    // flags for what details appear on shop floor print out.  Set the smarties on screen
    WireformQuoteFlags= {
        sfFinish: true,
        sfMaterial: false,
        sfMaterialSpec: true,
        sfWireDiameter: 0,
// for quotes
        qFinish: true,
        qMaterial: false,
        qMaterialSpec: true,
        qWireDiameter: 1,
    }
}

export class BendObj { // wireInfo on old version
    actual_length = null    // either the straight length or the length of teh arc
    summary = null          // for display purposes
    length = null           // straight length, no bends
    radius = null           // combines with angle to give an arc
    angle = null            // combines with radius to give an arc
}