import {createStore} from 'vuex'
import {SpringObj} from "@/store/SpringObj"
import {QuoteObj} from "@/store/QuoteObj"

import {SpringCostingObj} from "@/store/SpringCostingObj";
import {CostingObj} from "@/store/CostingObj";
import {WireformObj} from "@/store/WireformObj";
import {WireformCostingObj} from "@/store/WireformCostingObj"
import {LasercutObj} from "@/store/LasercutObj"
import {LasercutCostingObj} from "@/store/LaserCostingObj"
import {BoughtInObj} from "@/store/BoughtInObj"
import {BoughtInCostingObj} from "@/store/BoughtInCostingObj";


export default createStore({
    state: {
        /** The index of active selection/quote/part/costing tab */
        tabIndex: 0,        // always zero by default but ultimately depends on which area of the application we're in
        display_precision: 2,    // 2dp for decimal, 4dp for imperial
        site: null,  // each user will have a home site accessed at logon
        local_currency: 'GBP',  // so we can pull out the currency by country
        /** The currently worked on quote */
        Quote: new QuoteObj(),
        /** the current Part */
        Part: null,
        AppArea: null,      // quotes or calculator
        user: null,
        user_id: null,
        short_user: null,
    },
    getters: {
        /* The part type of the current part, null if no current part */
        CurrentPartType(state) {
            return state.Part?.PartType;
        },
        CurrentSite(state) {
            return state.site;
        },
        CurrentAppArea(state) {
            return state.AppArea
        },
        LocalCurrency(state) {
            return state.local_currency;
        },
        SiteApplications(state) {
            return {
                "quotes": state.has_quotes,
                "production": state.has_production,
                "calculator": state.has_calculator,
                "timekeeping": state.has_timekeeping,
                "modelling": state.has_financial_modelling,
                'inspection': state.has_inspection
            }
        },
        isUserAuthenticated(state) {
            return !!state.user;
        },
    },
    mutations: {
        setUserDetails(state, userdetails) {
            // stolen from Hauraton v3
            console.log("setUserDetails says", userdetails)
            state.user = userdetails['full_name']
            state.user_id = userdetails['user_id']
            state.short_user = userdetails['short_name']

            // Sentry.setContext("User Details", {
            //     country: user.country.iso_code,
            //     language: user.language,
            //     rainfall_units: rainfall_units,
            // });
            // Sentry.setUser({username: user.username});
        },
        resetUserAuthentication(state) {
            state.user = null;
            state.user_id = null
            state.short_user = null
            // Sentry.setContext("User Details", null);
            // Sentry.setUser(null);
        },
        setTab(state, index) {
            state.tabIndex = index;
            if (state.tabIndex > 0 && !state.CurrentPartType) {
                state.CurrentPartType = null  // so we can activate the appropriate links & tabs
            }
        },
        setCurrency(state, currency) {
            state.local_currency = currency;
        },
        setSite(state, site) {
            state.site = site.site
            state.local_currency = site.currency;
            state.has_quotes = site.quotes
            state.has_production = site.production
            state.has_calculator = site.calculator
            state.has_timekeeping = site.timekeeping
            state.has_financial_modelling = site.modelling
        },
        setUser(state, userdetails) {
            state.user = userdetails['full_name']
            state.user_id = userdetails['user_id']
            state.short_user = userdetails['short_name']
        },
        newSpring(state, opts) {
            let type = opts['type']
            if (opts.application === 'quotes') {
                // sales
                state.AppArea = 'quotes'
                state.Part = new SpringObj();
                state.Part.site = state.site
                state.Part.PartType = type;
                state.Part.setSpringDefaults();
                state.Costing = new SpringCostingObj(state.Part);
                state.Quote.parts.push(state.Costing);
                state.tabIndex = 2;   // 2 for sales, 1 for calculators
            } else {
                // calculator
                state.AppArea = 'calculator'
                state.Part = new SpringObj();
                state.Part.site = state.site
                state.Part.PartType = type;
                state.Part.setSpringDefaults();
                state.tabIndex = 1;  // 2 for sales, 1 for calculators
            }
        },
        resetSpring(state) {
            const spr_type = state.Part.PartType;
            state.Costing = new SpringCostingObj(state.Part);
            state.Part = state.Costing.Spring = new SpringObj();
            state.Part.PartType = spr_type;
            state.Part.setSpringDefaults()
        },
        newWireform(state, opts) {
            if (opts.application === 'quotes') {
                state.AppArea = 'quotes'
                state.Part = new WireformObj();
                state.Part.site = state.site
                state.Costing = new WireformCostingObj(state.Part);
                state.Quote.parts.push(state.Costing);
                state.tabIndex = 2;
            } else {
                // calculator
                state.AppArea = 'calculator'
                state.Part = new WireformObj();
                state.Part.site = state.site
                state.Part.PartType = 'W';
                state.tabIndex = 1;  // 2 for sales, 1 for calculators
            }

        },
        newLasercut(state) {
            state.Part = new LasercutObj();
            state.Part.site = state.site;
            state.Costing = new LasercutCostingObj(state.Part);
            state.Quote.parts.push(state.Costing);
            state.tabIndex = 4;
        },
        newBoughtIn(state) {
            state.Part = new BoughtInObj();
            state.Part.site = state.site;
            state.Costing = new BoughtInCostingObj(state.Part);
            state.Quote.parts.push(state.Costing);
            state.tabIndex = 4;
        },
        openExisting(state, part) {
            switch (part.PartType) {
                case 'C':
                case 'E':
                case 'T':
                    state.Costing = new SpringCostingObj(part);
                    break;
                case 'W':
                    state.Costing = new WireformCostingObj(); //TODO: add wireform routine
                    break;
                case 'F':
                    state.Costing = new CostingObj(); //TODO: add flat spring routine
                    break;
                case 'L':
                    state.Costing = new LasercutCostingObj(); //TODO: add lasercut routine
                    break;
                case 'X':
                    state.Costing = new CostingObj(); //TODO: add bought in routine
                    break;
                default:
                    console.warn('Unexpected part type', part)
                    return;
            }
            state.Part = state.Costing.Part = part;
            //Pick the right costing bits here too
            state.Quote.parts.push(state.Costing);
        },
        newQuote(state, unchecked_status) {
            state.Quote = new QuoteObj();
            state.Quote.currency = state.local_currency  // can be changed by the user
            state.Quote.status = unchecked_status  // unchecked
            state.Quote.quoteBy = state.user
            state.tabIndex = 1;
        },
        async openExistingQuote(state, quote) {
            state.AppArea = 'quotes'    // obviously!
            const realQuote = new QuoteObj();
            quote.parts = quote.Costings.map(costing => {
                const spring = Object.assign(new SpringObj(), costing.Part);
                const realCosting = new SpringCostingObj(spring); //TODO: What if I'm not a spring
                delete costing.Part; //We've put this into the costing already above
                Object.assign(realCosting, costing);
                return realCosting;
            });
            Object.assign(realQuote, quote);
            state.Quote = realQuote;
            state.Costing = state.Quote.parts[0];
            state.Part = state.Costing.Part;
            state.tabIndex = 1;  // now select the quote tab

            switch (state.Costing.Part.PartType) {
                case 'C':
                case 'E':
                case 'T':
                    // console.log("index 234, before calculate spring", state.Costing.Part.Results)
                    await state.Costing.Part.calculateSpring()
                    console.log("index 234, before calculate spring", state.Costing.Part.Results)
                    // TODO: where have the results gone?
                    console.log("src/index.js 238", state.Costing.Part)
                    await state.Costing.validateSpringCosting()
                    state.Part.ValidPart = true
                    break;
                case 'W':
                    // TODO: add wireform routine
                    await state.Costing.validateWireformCosting()
                    state.Part.ValidPart = true
                    break;
                case 'L':
                    // TODO: add lasercut routine
                    await state.Costing.validateLaserCosting()
                    state.Part.ValidPart = true
                    break;
                case 'X':
                    // TODO: add bought in routine
                    await state.Costing.validateBoughtinCosting()
                    state.Part.ValidPart = true
                    break;
                case 'F':
                    // TODO: sometime in the future...
                    // validateFlatSpringCosting()
                    break
                case 'K':
                    // TODO: sometime in the future...
                    // validateConstantForceCosting()
                    break
            }
        },
        switchParts(state, costing) {
            state.Costing = costing;
            state.Part = state.Costing.Part;
        },
    },
    actions: {
        async getUser({commit}) {
            const response = await fetch('/common/get_me');
            if (response.ok) {
                const data = await response.json();
                commit('setUser', data)
                commit('setSite', data.site);
                return true;
            } else {
                commit('setUser', null)
                commit('setSite', null);
                return false;
            }
        },

    },
    modules: {}
})
