import {calculationRelevant} from "@/store/decorators";
import {PartObj} from "@/store/PartObj";

export class LasercutObj extends PartObj {
    @calculationRelevant
    PartType = "L"       // wireform
    PartMaterial = null         // incorporate mild steel from the boolean
    Sheet_length = null
    Sheet_width = null
    Sheet_thickness = null
    Sheet_weight = null
    qty_per_sheet = null
    Specification = null
    Supplier = null
    Cuts = null             // not needed in this version?
    Ops = []              // this needs to by an array of objects
    qMaterial = true
    qWireDiameter = true
    sfMaterial = true
    sfWireDiameter = false
    @calculationRelevant
    Units = 'M'     // metric by default
    Results = {};
    sfFinish = true
    qFinish = true
}

export class LaserOpsObj {
    production_rate = null   // how many can be made in 1 hour
    hourly_cost = null
}