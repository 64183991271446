<template>
  <div class="wrapperx">

    <Dialog header="Header" v-model:visible="display">
      Content
    </Dialog>
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      display: false
    }
  },
  computed: {},
  methods: {
  }

}
</script>

<style>
html {
  /* this gives us a global sizing for the PrimeVue components */
  font-size: 11px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 11px !important;
}

</style>
