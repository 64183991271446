import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import store from "@/store";

async function isLoggedIn() {
    /*console.debug(store)
    const response = await fetch('/common/get_me');
    return response.ok ? true : {name: 'Login'};*/
    return await store.dispatch('getUser') ? true : {name: 'Login'};
}

const routes = [
    {
        path: '/home',
        name: 'Hello',
        component: Home,
        beforeEnter: [isLoggedIn],
    },
    {
        path: '/',
        name: 'Hello',
        // component: Home,
        component: () => import(/* webpackChunkName: "hello" */ '../views/LogIn.vue'),
        // beforeEnter: [isLoggedIn],
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/LogIn.vue')
    },
    {
        path: '/e8',
        name: 'e8',
        component: () => import(/* webpackChunkName: "e8" */ '../views/e8.vue'),
        beforeEnter: [isLoggedIn],
    },
    {
        path: '/sales',
        name: 'Sales',
        component: () => import(/* webpackChunkName: "sales" */ '../views/Sales.vue'),
        beforeEnter: [isLoggedIn],
    },
        {
        path: '/calculators',
        name: 'Calculators',
        component: () => import(/* webpackChunkName: "calculators" */ '../views/Calculators.vue'),
        beforeEnter: [isLoggedIn],
    },
    {
        path: '/crm',
        name: 'Crm',
        component: () => import(/* webpackChunkName: "crm" */ '../views/Crm.vue'),
        beforeEnter: [isLoggedIn],
    },
    {
        path: '/production',
        name: 'Production',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "production" */ '../views/Production.vue'),
        beforeEnter: [isLoggedIn],
    },
    {
        path: '/inspection',
        name: 'Inspection',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "inspection" */ '../views/Inspection.vue'),
        beforeEnter: [isLoggedIn],
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "maintenance" */ '../views/maintenance_admin.vue'),
        beforeEnter: [isLoggedIn],
        children: [
            // general
            {
                path: 'sites',
                name: 'site_maintenance',
                component: () => import( '@/components/maintenance/site_maintenance.vue'),
            },
            {
                path: 'user_maintenance',
                name: 'user_maintenance',
                component: () => import( '@/components/maintenance/user_maintenance.vue'),
            },
            {
                path: 'user_permission_maintenance',
                name: 'user_permission_maintenance',
                component: () => import( '@/components/maintenance/user_permission_maintenance.vue'),
            },
            {
                path: 'employees',
                name: 'employee_maintenance',
                component: () => import( '@/components/maintenance/employee_maintenance.vue'),
            },
            // sales
            {
                path: 'customer_maintenance',
                name: 'customer_maintenance',
                component: () => import( '@/components/maintenance/customer_maintenance.vue'),
            },
            {
                path: 'currency_maintenance',
                name: 'currency_maintenance',
                component: () => import( '@/components/maintenance/currency_maintenance.vue'),
            },
            {
                path: 'stored_comment_maintenance',
                name: 'stored_comment_maintenance',
                component: () => import( '@/components/maintenance/stored_comment_maintenance.vue'),
            },
            {
                path: 'delivery_maintenance',
                name: 'delivery_maintenance',
                component: () => import( '@/components/maintenance/delivery_maintenance.vue'),
            },
            {
                path: 'terms_maintenance',
                name: 'terms_maintenance',
                component: () => import( '@/components/maintenance/terms_maintenance.vue'),
            },
            {
                path: 'chase_method_maintenance',
                name: 'chase_method_maintenance',
                component: () => import( '@/components/maintenance/chase_method_maintenance.vue'),
            },
            {
                path: 'carriage_maintenance',
                name: 'carriage_maintenance',
                component: () => import( '@/components/maintenance/carriage_maintenance.vue'),
            },
            {
                path: 'inspection_maintenance',
                name: 'inspection_maintenance',
                component: () => import( '@/components/maintenance/inspection_maintenance.vue'),
            },
            {
                path: 'packing_maintenance',
                name: 'packing_maintenance',
                component: () => import( '@/components/maintenance/packing_maintenance.vue'),
            },
            // production
            {
                path: 'machine_type_maintenance',
                name: 'machine_type_maintenance',
                component: () => import( '@/components/maintenance/machine_type_maintenance.vue'),
            },
            {
                path: 'machine_maintenance',
                name: 'machine_maintenance',
                component: () => import( '@/components/maintenance/machine_maintenance.vue'),
            },
            {
                path: 'process_maintenance',
                name: 'process_maintenance',
                component: () => import( '@/components/maintenance/process_maintenance.vue'),
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
