//Would be nice if these could be static in Run... but that's beyond JS (for now at least)

/**
 * Callback for a decorated element
 *
 * @callback decoratorCallback
 * @param {string} kind The type of decorated element
 * @param {string} key The name of the decorated element
 */
/**
 * Make a decorator for fields and getters which runs through the given callback
 *
 * @param {decoratorCallback} callback The function to run for each decorated element
 */
function decorator(callback) {
	return function({kind, key}) {
		//console.debug(arguments);
		if (kind === undefined) {
			//Decorators are added via https://babeljs.io/docs/en/babel-plugin-proposal-decorators
			//The (current) spec expects a leading value argument to be added at some point
			//It probably will in Babel 8 when this is done: https://github.com/babel/babel/pull/13827
			if (arguments.length > 1 && 'kind' in arguments[1]) {
				kind = arguments[1].kind;
				key = arguments[1].key;
			} else {
				console.error("Incorrect behaviour in decorator, has Babel updated?", arguments);
				return;
			}
		}

		//Should just be field or getter, but getters show up as methods right now
		if (kind === 'field' || kind === 'getter' || kind === 'method') {
			callback(kind, key);
		} else {
			//We only expect to be applied to fields
			console.warn(`Misapplied decorator to ${kind} ${key}, expected field/getter`);
		}
	};
}

/**
 * Decorator to mark a field as used server side for hydraulic calculations
 *
 * @type {(function(function): void)}
 */
export const calculationRelevant = decorator((kind, key) => {
	//console.debug(`Noting calculation relevant field ${key}`);
	calculationRelevant.fields.push(key);
});
/**
 * Members of {@link Run} which are used server side for hydraulic calculations
 * @type {string[]}
 */
calculationRelevant.fields = [];

/**
 * Decorator to mark a field as used server side for producing PDFs
 *
 * @type {(function(function): void)}
 */
export const documentRelevant = decorator((kind, key) => {
	//console.debug(`Noting document relevant field ${key}`);
	documentRelevant.fields.push(key);
});
/**
 * Members of {@link Run} which are used server side for producing PDFs
 * @type {string[]}
 */
documentRelevant.fields = [];
