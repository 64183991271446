<template>
  <div>
    <router-link :to="{ path: '/LogIn' }">
      <Button>LogIn</Button>
    </router-link>
    <router-link :to="{ path: '/sales' }">
      <Button>Sales</Button>
    </router-link>
    <router-link :to="{ path: '/production' }">
      <Button>Production</Button>
    </router-link>
  </div>
  <div>
    <img src="../assets/images/episode_8.png" alt="Episode VIII"/>
  </div>
</template>

<script>
// @ is an alias to /src

export default {}
</script>

<style>

</style>