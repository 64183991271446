import {CarriageObj, CostingObj, FinishingObj, InspectionObj, OtherObj, PackingObj} from "@/store/CostingObj";
import store from "@/store/index";
import Cookie from "js-cookie";

export class BI_costsObj {
    lead_time = null
    qty1_val = null
    qty1_cost = null
    qty2_val = null
    qty2_cost = null
    qty3_val = null
    qty3_cost = null
    qty4_val = null
    qty4_cost = null
    qty5_val = null
    qty5_cost = null
    boughtin_margin = 80  // percentage increase
}

export class BoughtInCostingObj extends CostingObj {

    constructor(part) {
        super();
        this.Part = part;
        this.BI_costs = new BI_costsObj();
        this.Finishing = new FinishingObj()
        this.Inspection = new InspectionObj()
        this.Packing = new PackingObj()
        this.Carriage = new CarriageObj()
        this.Other = new OtherObj()
        this.AncillaryItems = []  // empty list to add object to
    }

// needs validation routines in here?
    async validateBoughtinCosting() {
        console.log("this needs to be a common routine...", this)
        if (this.Qty1 && this.BI_costs.qty1_cost > 0 && this.BI_costs.qty1_cost > 1 && !!this.BI_costs.boughtin_margin) {
            console.log('show time')
            let costingURL = `/sales/calculate_boughtin_costs/`;
            const site = store.getters.CurrentSite;
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'X-CSRFToken': Cookie.get('csrftoken') ?? '',
                },
                body: JSON.stringify({costing: this, 'site': site})
            };
            fetch(costingURL, requestOptions)
                .then(response => response.json())
                .then(data => {
                    //Update ourselves with the new values
                    for (const [property, value] of Object.entries(data.return_costing)) {
                        if (value === null) continue; //Don't null out fields
                        this[property] = value
                    }

                    this.Results = data.costing_results
                    console.log("RESULTS", data)
                });
        }

    }
}