import {calculationRelevant} from "@/store/decorators";

export class PartObj {
    @calculationRelevant
    site = null
    PartType = null // can be spring (C, E, T [V, but not yet]), Wireform (W), Bought in (X') Laser Cut (L), Flat spring (F)
    PartNo = null      // must be provided
    PartDescription = null  // must be provided
    PartFinish = null   // what type of finish will be provided
    // PartUnits = 'M'     // define here for simplicity of unit conversion, default to metric
    Revision = null       // as specified by the customer
    DwgNo = null       // as specified by the customer
    Internal = false // used to denote it being used to build bigger, saleable parts
    ValidPart = false; // bool to allow access to costing tab
}