export function isNumeric(str) {
    if (typeof str != "string" && typeof str !== 'number') return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export default {
    computed: {
        displayPrecision() {
            const part = this.$store.state.Part;
            if (part && part.Units === 'I') {
                return 4
            } else {
                return 2
            }
        },
    },
    methods: {
        isNumberKey(evt) {
            //Cover control characters (such as backspace)
            if (evt.keyCode <= 31 || evt.keyCode === 46) return true;
            //Allow number characters from either the numpad or normal number buttons
            switch (evt.key) {
                case '0':
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                case '.':
                    return true;
                default:
                    evt.preventDefault();
                    return false;
            }
        },
        printoutButtonValueOnly(key) {
            const part = this.$store.state.Part;
            console.log("quoteflag", key)
            console.log(part.quoteflags[key])
            part.quoteflags[key] = !part.quoteflags[key];
        },
        printoutButtonTolerance(key) {
            // this is a tristate, 0 = false, 1 = true, 3 = show tolerances
            const part = this.$store.state.Part;
            // console.log("quoteflag", key)
            // console.log(part.quoteflags[key])
            if (part.quoteflags[key] < 2) {
                part.quoteflags[key]++
            } else {
                part.quoteflags[key] = 0
            }

        },
        buttonToleranceClass(key) {
            const part = this.$store.state.Part;
            // console.log("quoteflag BC", key)
            // console.log(part.quoteflags[key])
            switch (part.quoteflags[key]) {
                case 0:
                    return 'blank-smarty'
                case 1:
                    return 'green-smarty'
                case 2:
                    return 'orange-smarty'
                case 3:
                    return 'orange-smarty'
            }
        },
        highlight_stress(value) {
            if (value) {
                //Check the last character in the string
                switch (value.charAt(value.length - 1)) {
                    case 'X':
                        return "overstressed";
                    case 'P':
                        return "prestressed";
                    case 'U':
                        return "unstressed";
                    default:
                        return "";
                }
            } else {
                return ""
            }

        },
        angleInDegrees(a) {
            if (a) {
                return parseFloat(a).toFixed(1) + '°'
            } else {
                return null
            }
        },
        massInKg(M) {
            if (M) {
                return parseFloat(M).toFixed(2) + ' kg'
            } else {
                return null
            }
        },
        metricWeight() {
            if(this.$store.state.Part === null || this.$store.state.Part.Units === 'M'){
                return ' kg'
            } else {
                return ' lb'
            }
        },
        metricLoad() {
            if (this.$store.state.Part === null || this.$store.state.Part.Units === 'M'){
                return ' N'
            } else {
                return ' lbf'
            }
        },
        metricRate() {
            if (this.$store.state.Part === null || this.$store.state.Part.Units === 'M') {
                return ' N/mm'
            } else {
                return ' lbf/inch'
            }
        },
        metricStress() {
            if (this.$store.state.Part === null || this.$store.state.Part.Units === 'M'){
                return ' N/mm<sup>2</sup>'
            } else {
                return ' lbf/inch'
            }
        },
        metricTorque() {
            if (this.$store.state.Part === null || this.$store.state.Part.Units === 'M'){
                return ' Nm'
            } else {
                return ' lb-inch'
            }
        },
        forceInNewtons(F, n) {
            if (F) {
                if (this.$store.state.Part === null || this.$store.state.Part.Units === 'M'){
                    return parseFloat(F).toFixed(n) + ' N'
                } else {
                    return parseFloat(F).toFixed(n) + ' lbf'
                }
            } else {
                return null
            }
        },
        metricLength() {
            try {
                if (this.$store.state.Part == null || this.$store.state.Part.Units === 'M') {
                    return ' mm'
                } else {
                    return ' in'
                }
            } catch (error) { // we usually get here by going back to the quote without a spring design
                console.log("error on 132", error)
                return ''
            }
        },
        lengthInmm(L, n) {
            if (L) {
                if (this.$store.state.Part === null || this.$store.state.Part.Units === 'M'){
                    return parseFloat(L).toFixed(n) + ' mm'
                } else {
                    return parseFloat(L).toFixed(n) + ' in'
                }
            } else {
                return null
            }
        },
        RateInNmm(L, n) {
            if (this.$store.state.Part === null || this.$store.state.Part.Units === 'M'){
                return parseFloat(L).toFixed(n) + ' N/mm'
            } else {
                return ' lbf/inch'
            }
        },
        weightInKg(L, n) {
            if (this.$store.state.Part === null || this.$store.state.Part.Units === 'M'){
                return parseFloat(L).toFixed(n) + ' kg'
            } else {
                return ' lbf'
            }
        },
        stressInNmm2(S, n) {
            if (S) {
                if (this.$store.state.Part === null || this.$store.state.Part.Units === 'M'){
                    return parseFloat(S).toFixed(n) + ' N/mm<sup>2</sup>'
                } else {
                    return parseFloat(S).toFixed(n) + ' lbf/inch<sup>2</sup>'
                }
            } else {
                return null
            }
        },
        percentValue(v, n) {
            if (v) {
                return parseFloat(v).toFixed(n) + '%'
            } else {
                return null
            }
        },
        ordinaryNumber(v, n) {
            if (v) {
                return parseFloat(v).toFixed(n)
            } else {
                return null
            }
        },
        currencyNumber(v) {
            if (v) {
                let formatter = new Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currency: 'GBP',
                })
                return formatter.format(v);
                // return '£' + parseFloat(v).toFixed(n)
            } else {
                return null
            }
        }
    },
};