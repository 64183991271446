import {calculationRelevant} from "@/store/decorators";
import {PartObj} from "@/store/PartObj";

export class BoughtInObj extends PartObj {
    @calculationRelevant
    PartType = "X"       // BoughtIn
    Supplier = null
    SupplierPartNo = null
    SupplierDescription = null
    sfFinish = true
    qFinish = true
    Results = {};
}

